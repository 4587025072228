import React from "react"
import { PressReleaseContentProps } from "../components/pressrelease-content/PressReleaseContent"
import { PageProps } from "gatsby"

const ClientSideOnlyLazy = React.lazy(() => import('../sections/PressRelease'));

const Article: React.FC<PageProps<object, PressReleaseContentProps>> = (props) => {
  return (
    <React.Suspense fallback={''}>
      <ClientSideOnlyLazy {...props}/>
    </React.Suspense>
  );
}

export default Article